// export service_constant
const { REACT_APP_API_URL } = process.env;

const api_constant = {
  auth: {
    login: {
      baseURL: `${REACT_APP_API_URL}oauth`,
      api_path: "/login/",
      method: "post",
      request_name: "Login",
    },
  },
  // all endpoint for all ams feature start from this block
  ams: {
    getTag: {
      method: "get",
      request_name: "Get Ams tag",
      api_path: "workorder/tag/suggestion",
    },
    getLocation: {
      method: "get",
      request_name: "Get Ams location",
      api_path: "location/",
    },
    getLocationListByAssetId: {
      method: "get",
      module: "ams",
      request_name: "Get Ams location list",
      api_path: "workorder/asset/",
    },
    getLocationListByLocationId: {
      method: "get",
      request_name: "Get location list by location id",
      api_path: "location/get/",
    },
    // ASSET
    asset: {
      getAssetName: {
        method: "get",
        request_name: "Get asset name",
        api_path: "asset/suggestion",
      },
      getAssetList: {
        method: "post",
        request_name: "Get asset list",
        api_path: "asset/lists",
      },
      getAssetDetailGeneralInformation: {
        method: "get",
        request_name: "Get asset detail",
        api_path: "asset/mobile/detail",
        module: "ams",
      },
      getVendorListDisplay: {
        method: "get",
        request_name: "Get vendor list display",
        api_path: "asset/",
      },
      getAssetItemLists: {
        method: "post",
        request_name: "Get Item Lists by Asset ID",
        api_path: "asset/list-item",
        module: "ams",
      },
      addAssetGeneralInformation: {
        method: "post",
        request_name: "Create New Asset",
        api_path: "asset/add",
        module: "ams",
      },
      updateAssetGeneralInformation: {
        method: "put",
        request_name: "Edit Asset General Information",
        api_path: "asset/mobile/edit",
        module: "ams",
      },
      dashboardAsset: {
        method: "get",
        request_name: "Get asset dashboard data",
        api_path: "asset/dashboard",
        module: "ams",
      },
    },
    getAttachmentList: {
      method: "get",
      request_name: "Get attachment list on Asset AMS",
      module: "ams",
      api_path: "asset/",
    },
    addAttachment: {
      method: "post",
      request_name: "Add Attachment",
      module: "ams",
      api_path: "/asset-attachment/add",
    },
    deleteAttachment: {
      method: "put",
      request_name: "Delete Attachment",
      module: "ams",
      api_path: "/asset-attachment/delete",
    },
    getCustomFieldList: {
      method: "get",
      request_name: "Get Custom Field List",
      module: "ams",
      api_path: "asset/",
    },
    addCustomField: {
      method: "put",
      request_name: "Add Custom Field",
      module: "ams",
      api_path: "asset/update-custom-field",
    },

    sparepart: {
      getSparepartList: {
        method: "get",
        request_name: "Get Sparepart List",
        api_path: "/sparepart/lists",
        module: "ams",
      },
      getSparepartDetail: {
        method: "get",
        request_name: "Get Sparepart Detail",
        api_path: "/sparepart/get/",
        module: "ams",
      },
      getSparepartVendorList: {
        method: "get",
        request_name: "Get Vendor List from Sparepart",
        api_path: "/sparepart/",
        module: "ams",
      },
      getSparepartSimilar: {
        method: "get",
        request_name: "Get Similar Sparepart",
        api_path: "/sparepart/similar",
        module: "ams",
      },
      addSimilarSparepart: {
        method: "post",
        request_name: "Add Similar Sparepart",
        api_path: "/sparepart/similar",
        module: "ams",
      },
      deleteSimilarSparepart: {
        method: "delete",
        request_name: "Delete Similar on Sparepart",
        api_path: "/sparepart/similar",
        module: "ams",
      },
      getSparepartLogList: {
        method: "get",
        request_name: "Get Sparepart Log List",
        api_path: "/sparepart/log",
        module: "ams",
      },
      getSparepartworkorderLists: {
        method: "get",
        request_name: "Get Workorder History Lists",
        api_path: "/sparepart/workorder",
        module: "ams",
      },
      addSparepart: {
        method: "post",
        request_name: "Create New Sparepart",
        api_path: "/sparepart/add",
        module: "ams",
      },
      editSparepart: {
        method: "put",
        request_name: "Edit Sparepart",
        api_path: "sparepart/edit",
        module: "ams",
      },
      getListSparepart: {
        method: "get",
        request_name: "Get Sparepart - Asset List",
        api_path: "asset/",
        module: "ams",
      },
      updateSparepartVendor: {
        method: "put",
        request_name: "Update sparepart vendor",
        api_path: "/sparepart/vendor/update",
        module: "ams",
      },
      addSpareparts: {
        method: "put",
        request_name: "Create New Sparepart - Asset List",
        api_path: "asset/update-sparepart",
        module: "ams",
      },
      getSparepartSuggestionList: {
        method: "get",
        request_name: "Get Sparepart Suggestion List - Asset List",
        api_path: "sparepart/suggestion",
        module: "ams",
      },
      getListSimiliarSparepart: {
        method: "get",
        request_name: "Get Sparepart Similiar - Asset List",
        api_path: "sparepart/similar",
        module: "ams",
      },
    },
    general: {
      item: {
        getListsByLocation: {
          method: "get",
          module: "ams",
          request_name: "Get item by location",
          api_path: "/item/lists/location",
        },
        getItemIdListByLocation: {
          method: "get",
          module: "ams",
          request_name: "Get item id by location",
          api_path: "item/suggestion",
        },
        getItemWorkorder: {
          method: "get",
          module: "ams",
          request_name: "Get Item Workorder",
          api_path: "/item/get/workorder",
        },
        getItemDetailGeneralInfo: {
          method: "get",
          request_name: "Get Item Detail",
          api_path: "item/mobile/detail",
          module: "ams",
        },
        getItemContract: {
          method: "get",
          request_name: "Get Item Contract",
          api_path: "item/mobile/contract",
          module: "ams",
        },
        getItemAdditional: {
          method: "get",
          request_name: "Get Item Additional",
          api_path: "item/mobile/additional",
          module: "ams",
        },
        addItem: {
          method: "post",
          request_name: "Create New Item",
          api_path: "item/add",
          module: "ams",
        },
        getFilterParentItem: {
          method: "post",
          request_name: "Get filter parent item",
          api_path: "/item/filter-parent",
          module: "ams",
        },
        getItemStatus: {
          method: "get",
          request_name: "Get Item Status Lists",
          api_path: "itemStatus/lists",
          module: "ams",
        },
        addItemStatus: {
          method: "post",
          request_name: "Create New Item Status",
          api_path: "itemStatus/add",
          module: "ams",
        },
        editItemStatus: {
          method: "put",
          request_name: "Edit Item Status",
          api_path: "itemStatus/edit",
          module: "ams",
        },
        deleteItemStatus: {
          method: "delete",
          request_name: "Delete Item Status",
          api_path: "itemStatus/delete",
          module: "ams",
        },
        getItemMovement: {
          method: "get",
          request_name: "Get Item Movement",
          api_path: "item/movement",
          module: "ams",
        },
        getItemchild: {
          method: "get",
          request_name: "get Item Child",
          api_path: "item/child-list",
          module: "ams",
        },
        getItemLog: {
          method: "get",
          request_name: "Get Item Log",
          api_path: "item/log",
          module: "ams",
        },
        updateItemGeneralInformation: {
          method: "put",
          request_name: "Update Item General Information",
          api_path: "item/mobile/update-detail",
          module: "ams",
        },
        updateItemWarrantyContract: {
          method: "put",
          request_name: "Update Item Warranty Contract",
          api_path: "item/contract/update-warranty",
          module: "ams",
        },
        updateItemMaintenanceContract: {
          method: "put",
          request_name: "Update Item Maintenance Contract",
          api_path: "item/contract/update-maintenance",
          module: "ams",
        },
        updateItemAdditionalInformation: {
          method: "put",
          request_name: "Update Item Additional Informationa",
          api_path: "item/mobile/update-additional",
          module: "ams",
        },
        addNewMaintenanceAttachment: {
          method: "post",
          request_name: "Add New Maintenance Attachment",
          api_path: "maintenance-attachment/add",
          module: "ams",
        },
        deleteMaintenanceAttachment: {
          method: "put",
          request_name: "Delete Maintenance Attachment",
          api_path: "maintenance-attachment/delete",
          module: "ams",
        },
        addNewWarrantyAttachment: {
          method: "post",
          request_name: "Add New Warranty Attachment",
          api_path: "warranty-attachment/add",
          module: "ams",
        },
        deleteWarrantyAttachment: {
          method: "put",
          request_name: "Delete Warranty Attachment",
          api_path: "warranty-attachment/delete",
          module: "ams",
        },
      },
      brand: {
        getAllAssetBrandLists: {
          method: "get",
          request_name: "Get all asset brand list",
          api_path: "brand/search",
        },
        getAmsBrandListsByBrandIds: {
          method: "post",
          request_name: "Get asset brand list by brand ids",
          api_path: "brand/lists",
          module: "ams",
        },
        getAllBrandLists: {
          method: "get",
          request_name: "Get All brand list",
          api_path: "brand/all",
          module: "ams",
        },
        addBrand: {
          method: "post",
          request_name: "Create New Brand",
          api_path: "brand/add",
          module: "ams",
        },
        editBrand: {
          method: "put",
          request_name: "Edit Brand",
          api_path: "brand/edit",
          module: "ams",
        },
        deleteBrand: {
          method: "put",
          request_name: "Delete Brand",
          api_path: "brand/delete",
          module: "ams",
        },
      },
      type: {
        getAllAssetTypeLists: {
          method: "get",
          request_name: "Get all asset type list",
          api_path: "asset-type/search",
        },
        getAmsTypeLists: {
          method: "post",
          request_name: "Get asset type list",
          api_path: "asset-type/list",
        },
        getAmsTypeListsByLocationId: {
          method: "get",
          request_name: "Get asset type list",
          module: "ams",
          api_path: "asset-type/search",
        },
        createType: {
          method: "post",
          request_name: "Create New Type",
          module: "ams",
          api_path: "asset-type/add",
        },
        editType: {
          method: "put",
          request_name: "Edit Type on AMS",
          module: "ams",
          api_path: "asset-type/update",
        },
        deleteType: {
          method: "put",
          request_name: "Delete Type on AMS",
          module: "ams",
          api_path: "asset-type/delete",
        },
      },
      category: {
        getAllAssetCategoryLists: {
          method: "get",
          request_name: "Get all asset category list",
          api_path: "asset-category/search",
        },
        getAssetCategoryLists: {
          method: "post",
          request_name: "Get asset category list",
          api_path: "asset-category/list",
        },
        createCategory: {
          method: "post",
          request_name: "Create New Category",
          module: "ams",
          api_path: "asset-category/add",
        },
        editCategory: {
          method: "put",
          request_name: "Edit Category on AMS",
          module: "ams",
          api_path: "asset-category/update",
        },
        deleteCategory: {
          method: "put",
          request_name: "Delete Category on AMS",
          module: "ams",
          api_path: "asset-category/delete",
        },
      },
      workorder_status: {
        getLists: {
          method: "get",
          module: "ams",
          request_name: "Get workorder status lists",
          api_path: "/workorder/status/list",
        },
        updateWOStatus: {
          method: "put",
          module: "ams",
          request_name: "Update workorder status",
          api_path: "/workorder/update-status",
        },
      },
    },

    workorder: {
      getLists: {
        method: "get",
        request_name: "Get workorder corrective lists",
        api_path: "workorder/lists/",
        module: "ams",
      },
      getGanttChart: {
        method: "get",
        request_name: "Get workorder corrective ganttchart",
        api_path: "workorder/gantchart/",
      },
      serviceCost: {
        add: {
          method: "post",
          module: "ams",
          request_name: "Add workorder service cost lists",
          api_path: "/costList/add/",
        },
        get: {
          method: "get",
          module: "ams",
          request_name: "Get workorder service cost lists",
          api_path: "/workorder/cost",
        },
        delete: {
          method: "put",
          module: "ams",
          request_name: "Delete workorder service cost lists",
          api_path: "/costList/delete",
        },
      },
      log: {
        method: "get",
        module: "ams",
        request_name: "Get workorder log",
        api_path: "/workorder/log",
      },
      assignUsers: {
        method: "post",
        module: "ams",
        request_name: "Add assign user",
        api_path: "/workorder/assign-user",
      },
      deleteAssignedUser: {
        method: "delete",
        module: "ams",
        request_name: "delete assigned user",
        api_path: "/workorder/delete/assigned-user",
      },
      updateChecklist: {
        method: "put",
        module: "ams",
        request_name: "Update workorder checklist",
        api_path: "/workorder/update-checklist",
      },
      updateItemStatus: {
        method: "post",
        module: "ams",
        request_name: "Update workorder item status",
        api_path: "/item/update-status",
      },
      updateTestcommList: {
        method: "put",
        module: "ams",
        request_name: "Update workorder testcomm lists",
        api_path: "testcommList/update",
      },

      solutionBank: {
        get: {
          method: "get",
          module: "ams",
          request_name: "Get workorder solution bank",
          api_path: "/workorder/mobile/cause",
        },
      },
      checklist: {
        getChecklist: {
          method: "get",
          module: "ams",
          request_name: "Get workorder checklists",
          api_path: "/workorder/checklist",
        },
      },
      updateCauseWorkorder: {
        method: "put",
        module: "ams",
        request_name: "Update cause workorder",
        api_path: "/workorder/update-cause",
      },
      updateSolutionBankWorkorder: {
        method: "put",
        module: "ams",
        request_name: "Update solution bank workorder",
        api_path: "/solution/updateByWorkorder",
      },
      getTestcommList: {
        method: "get",
        module: "ams",
        request_name: "Get workorder testcomm list",
        api_path: "/workorder/testcommlist",
      },
      comment: {
        getComment: {
          method: "get",
          module: "ams",
          request_name: "Get workorder comment",
          api_path: "/comment/workorder",
        },
        addComment: {
          method: "post",
          module: "ams",
          request_name: "Add comments",
          api_path: "/comment/add/",
        },
      },
      detail: {
        method: "get",
        module: "ams",
        request_name: "Get workorder detail",
        api_path: "/workorder/detail",
      },
      updateUsingMaintenanceWarranty: {
        method: "put",
        module: "ams",
        request_name: "Update workorder using maintenance or warranty",
        api_path: "/workorder/update/warranty-maintenance",
      },
      locationSuggestion: {
        method: "get",
        module: "ams",
        request_name: "get location by asset",
        api_path: "/workorder/asset",
      },
      sparepart_list: {
        add: {
          method: "post",
          module: "ams",
          request_name: "Add sparepart list",
          api_path: "/sparepartList/add",
        },
        getLists: {
          method: "get",
          module: "ams",
          request_name: "get sparepart list",
          api_path: "/sparepartList/lists",
        },
        delete: {
          method: "put",
          module: "ams",
          request_name: "delete workorder sparepart list",
          api_path: "/sparepartList/delete",
        },
        getListsByAsset: {
          method: "get",
          module: "ams",
          request_name: "get sparepart list by asset",
          api_path: "/sparepart-by-asset",
        },
      },
      changeCorrectiveSchedule: {
        method: "post",
        module: "ams",
        request_name: "Change schedule workorder corrective",
        api_path: "/workorder/change-schedule",
      },
      // workorder preventive
      preventive: {
        addGeneralInfo: {
          method: "post",
          request_name: "Get preventive general information list",
          api_path: "/workorder/preventive-scheduling/addGeneralInfo",
        },
        addItems: {
          method: "post",
          request_name: "Get preventive add items list",
          api_path: "/workorder/preventive-scheduling/addItems",
        },
        addItemsTime: {
          method: "post",
          request_name: "Get preventive add items time list",
          api_path: "/workorder/preventive-scheduling/addItemsTime",
        },
        addAttachment: {
          method: "post",
          request_name: "Get preventive add attachment list",
          api_path: "/workorder/preventive-scheduling/addAttachment",
        },
      },
      // workorder preventive scheduling
      preventiveScheduling: {
        getLists: {
          method: "get",
          request_name: "Get preventive scheduling lists",
          api_path: "workorder/preventive-scheduling/getLists",
        },
        addGeneralInformation: {
          method: "post",
          module: "ams",
          request_name: "Add New Preventive Scheduling General Information",
          api_path: "/workorder/preventive-scheduling/addGeneralInfo",
        },
        addTimes: {
          method: "post",
          module: "ams",
          request_name: "Add New Preventive Scheduling Item Times",
          api_path: "/workorder/preventive-scheduling/addItemsTime",
        },
        addItem: {
          method: "post",
          module: "ams",
          request_name: "Add New Preventive Scheduling Item",
          api_path: "/workorder/preventive-scheduling/addItems",
        },
        addAttachment: {
          method: "post",
          module: "ams",
          request_name: "Add New Preventive Scheduling Attachment",
          api_path: "/workorder/preventive-scheduling/addAttachment",
        },
        getDetailGeneralInfo: {
          method: "get",
          module: "ams",
          request_name: "Get detail preventive scheduling general info",
          api_path: "/workorder/preventive/general",
        },
        getDetailItems: {
          method: "get",
          module: "ams",
          request_name: "Get detail preventive scheduling items",
          api_path: "/workorder/preventive/item",
        },
        getDetailTimeItems: {
          method: "get",
          module: "ams",
          request_name: "Get detail preventive scheduling item times",
          api_path: "/workorder/preventive/time",
        },
        getAttachment: {
          method: "get",
          module: "ams",
          request_name: "Get detail preventive scheduling attachment",
          api_path: "/workorder/preventive/attachment",
        },
        getHistory: {
          method: "get",
          module: "ams",
          request_name: "Get detail preventive scheduling history",
          api_path: "/workorder/preventive/history",
        },
        changeChildSchedule: {
          method: "put",
          module: "ams",
          request_name: "Change child schedule",
          api_path: "/workorder/preventive-scheduling/changeSchedule",
        },
        getPreventiveSchedulingHistory: {
          method: "get",
          module: "ams",
          request_name: "Get Wo History",
          api_path: "/workorder/preventive-scheduling/history",
        },
        deletePreventiveSchedulingAttachment: {
          method: "put",
          module: "ams",
          request_name: "Delete preventive scheduling attachment",
          api_path: "/workorder/preventive-scheduling/deleteAttachment",
        },
        cancelSchedule: {
          method: "put",
          module: "ams",
          request_name: "Cancelschedule",
          api_path: "/workorder/preventive-scheduling/cancelSchedule",
        },
        assignEmployeePreventive: {
          method: "post",
          module: "ams",
          request_name: "Assign Employee",
          api_path: "/workorder/preventive-scheduling/assign-employee",
        },
      },
      // workorder preventive corrective
      corrective: {
        add: {
          method: "post",
          request_name: "Add workorder corrective",
          api_path: "workorder/corrective/add/",
        },
        update: {
          method: "post",
          module: "ams",
          request_name: "update detail workorder corrective",
          api_path: "workorder/web/add-item",
        },
      },
    },
    amsVendor: {
      getVendorList: {
        method: "get",
        request_name: "Get All Vendor",
        api_path: "/vendor/list",
        module: "ams",
      },
      getVendorDetail: {
        method: "get",
        request_name: "Get Vendor By ID",
        api_path: "/vendor/",
        module: "ams",
      },
      createVendor: {
        method: "post",
        request_name: "Create Vendor",
        api_path: "/vendor/add",
        module: "ams",
      },
      updateVendor: {
        method: "put",
        request_name: "Update Vendor",
        api_path: "/vendor/update",
        module: "ams",
      },
      deleteVendor: {
        method: "put",
        request_name: "Delete Vendor",
        api_path: "/vendor/delete	",
        module: "ams",
      },
      getVendorAttachmentList: {
        method: "get",
        request_name: "Get Vendor Attachment Lists",
        api_path: "/vendor-attachment/list",
        module: "ams",
      },
      uploadAttachment: {
        method: "post",
        request_name: "Upload Attachment",
        api_path: "/vendor-attachment/add",
        module: "ams",
      },
      updateAttachment: {
        method: "put",
        request_name: "Update Vendor Attachment",
        api_path: "/vendor-attachment/update",
        module: "ams",
      },
      deleteAttachment: {
        method: "put",
        request_name: "Delete Vendor Attachment",
        api_path: "/vendor-attachment/delete",
        module: "ams",
      },
      getRelatedItem: {
        method: "get",
        request_name: "Get Related Item by Vendor",
        api_path: "/item/lists-by-vendor",
        module: "ams",
      },
      getVendorWorkorderHistory: {
        method: "get",
        request_name: "Get Workorder History by Vendor",
        api_path: "/item/used-by-vendor",
        module: "ams",
      },
      getVendorListAsset: {
        method: "get",
        request_name: "Get vendor list display",
        api_path: "asset/",
        module: "ams",
      },
      getSuggestionVendorList: {
        method: "get",
        request_name: "Get suggestion vendor list",
        api_path: "vendor/suggestion",
        module: "ams",
      },
      updateSuggestionVendorList: {
        method: "put",
        request_name: "Update Vendor list on Asset list - AMS",
        api_path: "asset/update-vendor",
        module: "ams",
      },
      setDefaultVendorList: {
        method: "put",
        request_name: "Set Default Vendor list on Asset list - AMS",
        api_path: "asset/vendor/default",
        module: "ams",
      },
    },
  },
  amsSparepart: {
    getSparepartList: {
      method: "get",
      request_name: "Get Sparepart List",
      api_path: "/sparepart/lists",
      module: "ams",
    },
    getSparepartDetail: {
      method: "get",
      request_name: "Get Sparepart Detail",
      api_path: "/sparepart/get/",
      module: "ams",
    },
    getSparepartVendorList: {
      method: "get",
      request_name: "Get Vendor List from Sparepart",
      api_path: "/sparepart/",
      module: "ams",
    },
    getSparepartSimilar: {
      method: "get",
      request_name: "Get Similar Sparepart",
      api_path: "/sparepart/similar",
      module: "ams",
    },
    getSparepartLogList: {
      method: "get",
      request_name: "Get Sparepart Log List",
      api_path: "/sparepart/log",
      module: "ams",
    },
    getSparepartworkorderLists: {
      method: "get",
      request_name: "Get Workorder History Lists",
      api_path: "/sparepart/workorder",
      module: "ams",
    },
    addSparepart: {
      method: "post",
      request_name: "Create New Sparepart",
      api_path: "/sparepart/add",
      module: "ams",
    },
    editSparepart: {
      method: "put",
      request_name: "Edit Sparepart",
      api_path: "sparepart/edit",
      module: "ams",
    },
  },

  // all endpoint for all ams feature end from this block
  dashboard: {
    getDashboardReminderData: {
      method: "get",
      request_name: "Get reminder dashboard data",
      api_path: "reminder/dashboard",
    },
    getDashboardEventData: {
      method: "post",
      request_name: "Get event dashboard data",
      api_path: "event/website/dashboard",
    },
    getDashboardLostFoundPackageData: {
      method: "get",
      request_name: "Get Lost, Found & Package dashboard data",
      api_path: "lostAndFoundPackage/dashboard/",
    },
    getDashboardWorkorder: {
      method: "get",
      request_name: "Get dashboard data",
      module: "ams",
      api_path: "workorder/dashboard",
    },
    getDashboardWorkorderJaga: {
      method: "post",
      request_name: "Get Dashboard workorder jaga",
      module: "ams",
      api_path: "workorder/additional-dashboard",
    },
    getDashboardWorkorderJagaDetail: {
      method: "post",
      request_name: "Get Dashboard workorder jaga detail",
      module: "ams",
      api_path: "workorder/additional-dashboard/list",
    },
    getDashboardEventJagaDetail: {
      method: "post",
      request_name: "Get Dashboard event jaga detail",
      module: "rms",
      api_path: "event/additional-dashboard/list",
    },
    getDashboardEventJaga: {
      method: "post",
      request_name: "Get Dashboard event jaga",
      module: "rms",
      api_path: "event/additional-dashboard",
    },
    v2: {
      getDashboardData: {
        method: "post",
        request_name: "Get Dashboard event jaga",
        module: "rms",
        version: "V2",
        api_path: "/event/dashboard",
      },
    },
  },
  event: {
    assign: {
      method: "put",
      request_name: "Assign employee",
      api_path: "/event/assign/",
    },
    deleteAssignedUser: {
      method: "delete",
      request_name: "Delete assigned employee",
      api_path: "/event/assigned-user",
    },
    addEvent: {
      method: "post",
      request_name: "Add new event",
      api_path: "/event/add/",
    },
    getEventLists: {
      method: "post",
      request_name: "Get event lists",
      api_path: "/event/lists",
    },
    getEventDetail: {
      method: "get",
      request_name: "Get event detail",
      api_path: "/event/detail",
    },
    getEventComments: {
      method: "get",
      request_name: "Get event comments",
      api_path: "/comment/event/get",
    },
    getEventLog: {
      method: "get",
      request_name: "Get event log",
      api_path: "/event/geteventlog",
    },
    getCategoryLists: {
      method: "get",
      request_name: "Get category lists",
      api_path: "category/lists",
    },
    getTagLists: {
      method: "get",
      request_name: "Get tag lists",
      api_path: "/tag/getbycategory/",
    },
    getLocationLists: {
      method: "get",
      request_name: "Get tag lists",
      api_path: "/location/getlistsbycompany/",
    },
    getEventStatusLists: {
      method: "get",
      request_name: "Get event status lists",
      api_path: "/event/getEventStatusLists",
    },
    updateEventStatus: {
      method: "put",
      request_name: "Update event status",
      api_path: "/event/updateEventStatus",
    },
    changeScheduleEvent: {
      method: "put",
      request_name: "Update event schedule",
      api_path: "/event/changeSchedule",
    },
    updateEventData: {
      method: "put",
      request_name: "Update event data",
      api_path: "/event/updateEventData",
    },
    getServiceEventList: {
      method: "get",
      request_name: "Add service event",
      api_path: "/event-costs",
    },
    addServiceEvent: {
      method: "post",
      request_name: "Add service event",
      api_path: "/event-cost",
    },
    deleteServiceEvent: {
      method: "delete",
      request_name: "Add service event",
      api_path: "/event-cost",
    },
    v2: {
      getEvents: {
        method: "post",
        request_name: "Get event list v2",
        version: "V2",
        api_path: "/events",
      },
    },
    // getUserByDepartment: {
    // 	method: "get",
    // 	request_name: "Get user lists",
    // 	api_path: "/user/getbydepartment/",
    // },
  },
  eventScheduled: {
    addEvent: {
      method: "post",
      request_name: "Add new event scheduled",
      api_path: "/event/scheduled/add/",
    },
    assign: {
      method: "post",
      request_name: "Assign employee",
      api_path: "/event/scheduled/assign",
    },
    cancel: {
      method: "put",
      request_name: "Cancel scheduled event",
      api_path: "/event/scheduled/cancel",
    },
    changeSchedule: {
      method: "put",
      request_name: "Change schedule event",
      api_path: "/event/scheduled/changeSchedule",
    },
    getLists: {
      method: "post",
      request_name: "Get event scheduled lists",
      api_path: "/event/scheduled/lists",
    },
    getDetail: {
      method: "get",
      request_name: "Get event scheduled detail",
      api_path: "/event/scheduled/detail",
    },
    getEventScheduledHistory: {
      method: "get",
      request_name: "Get event scheduled history",
      api_path: "/event/scheduled/history",
    },
  },
  comment: {
    addComment: {
      method: "post",
      request_name: "Add comments",
      api_path: "/comment/add/",
    },
    deleteComment: {
      method: "delete",
      request_name: "Delete comment",
      api_path: "/comment/delete",
    },
    getItemComments: {
      method: "get",
      request_name: "Get item comments",
      api_path: "/comment/item/get/",
    },
    getEventComments: {
      method: "get",
      request_name: "Get event comments",
      api_path: "/comment/event/get/",
    },
  },
  locationOverview: {
    moveItem: {
      method: "post",
      module: "ams",
      request_name: "Move item location overview",
      api_path: "item/move",
    },
    replaceItem: {
      method: "post",
      module: "ams",
      request_name: "Replace item location overview",
      api_path: "item/replace",
    },
    assignItem: {
      method: "post",
      module: "ams",
      request_name: "Assign item location overview",
      api_path: "item/assign",
    },
    getLocationWorkorderOverview: {
      method: "get",
      module: "ams",
      request_name: "Get location workorder overview",
      api_path: "workorder/location-overview",
    },
    getWorkorderListsByLocationOverview: {
      method: "get",
      request_name: "Get Workorder Lists by Location Overview",
      module: "ams",
      api_path: "/workorder/location-overview-lists",
    },
    getOverviewAssetItemOnLocation: {
      method: "get",
      module: "ams",
      request_name: "Get Overview Asset Item on Location",
      api_path: "item/location-overview",
    },
    getItemListsByLocationOverview: {
      method: "get",
      module: "ams",
      request_name: "Get Item Lists on Location Overview",
      api_path: "/item/overview-asset",
    },
    getItemListsByAssetType: {
      method: "get",
      module: "ams",
      request_name: "Get Item Lists by Asset Type",
      api_path: "/item/lists",
    },
  },
  lostAndFoundPackage: {
    addItem: {
      method: "post",
      request_name: "Add lost / found item",
      api_path: "/lostAndFoundPackage/addItem/",
    },
    editItem: {
      method: "put",
      request_name: "Edit lost / found  item",
      api_path: "/lostAndFoundPackage/editItem/",
    },
    getLists: {
      method: "get",
      request_name: "Get found items",
      api_path: "/lostAndFoundPackage/getLists/",
    },
    getItemLog: {
      method: "get",
      request_name: "Get item log",
      api_path: "/lostAndFoundPackage/getItemLog/",
    },
  },
  reports: {
    getSummaryEmployeeLists: {
      method: "post",
      request_name: "Get employee lists by summary",
      api_path: "/report/user-summary",
    },
    Employee: {
      Lists: {
        method: "post",
        request_name: "Get employee report lists",
        api_path: "/report/employee-lists",
      },
      Filters: {
        method: "post",
        request_name: "Get employee report list filter",
        api_path: "/report/employee-filter",
      },
      Detail: {
        method: "get",
        request_name: "Get employee report detail",
        api_path: "/report/employee-detail",
      },
    },
    getSummaryTagLists: {
      method: "get",
      request_name: "Get tag lists by summary",
      api_path: "/report/tag-summary",
    },
    getLocationTagLists: {
      method: "post",
      request_name: "Get tag lists by location",
      api_path: "/report/tag/location",
    },
    getEventLists: {
      method: "post",
      request_name: "Get event lists",
      api_path: "/report/event/list-event",
    },
    getFilterEventLists: {
      method: "post",
      module: "rms",
      request_name: "Get filter event lists report",
      api_path: "/report/event/filter",
    },
    getEventReportDetail: {
      method: "get",
      module: "rms",
      request_name: "Get report event detail",
      api_path: "/report/event/detail",
    },
    getTagEventLists: {
      method: "post",
      request_name: "Get event lists by tag",
      api_path: "/report/event/tag",
    },
    getStatusEventLists: {
      method: "post",
      request_name: "Get event lists by status",
      api_path: "/report/status/list-event",
    },
    getStatusEventCardLists: {
      method: "post",
      request_name: "Get event lists card by status",
      api_path: "/report/event/status",
    },
    getLocationEventLists: {
      method: "post",
      request_name: "Get event lists by location",
      api_path: "/report/event/location",
    },
    getEmployeeEventLists: {
      method: "post",
      request_name: "Get event lists by employee",
      api_path: "/report/event/employee",
    },
    getUserLists: {
      method: "post",
      request_name: "Get user by department",
      api_path: "/user/getbydepartment/filtering",
    },
    getLocationLists: {
      method: "get",
      request_name: "Get location by area",
      api_path: "/location/getbyarea",
    },
    getTagListsUser: {
      method: "get",
      request_name: "Get tag by user",
      api_path: "/tag/getbyuser",
    },
    getTagListsCategory: {
      method: "post",
      request_name: "Get tag by category",
      api_path: "/tag/multi-category",
    },
    getEventDetail: {
      method: "get",
      request_name: "Get event detail",
      api_path: "/report/event/detail",
    },
    Tag: {
      List: {
        method: "post",
        module: "rms",
        request_name: "Get Tag Report List",
        api_path: "/report/tag-lists",
      },
      Filter: {
        method: "post",
        module: "rms",
        request_name: "Get Tag Report Filter List",
        api_path: "/report/filter-tag-lists",
      },
      Summary: {
        method: "get",
        api_path: "/report/tag-summary",
      },
      SummaryRms: {
        method: "get",
        api_path: "/report/tag-summary/rms",
      },
    },
    Package: {
      Filter: {
        method: "post",
        module: "rms",
        request_name: "Get Package Report Filter",
        api_path: "/report/item/filter",
      },
      List: {
        method: "post",
        module: "rms",
        request_name: "Get Package Report List",
        api_path: "/report/item/list",
      },
      Detail: {
        method: "get",
        module: "rms",
        request_name: "Get Package Report Detail",
        api_path: "/report/item/detail",
      },
    },
    Reminder: {
      Filter: {
        method: "post",
        module: "rms",
        request_name: "Get Reminder Report Filter",
        api_path: "/report/reminder/filter",
      },
      List: {
        method: "post",
        module: "rms",
        request_name: "Get Reminder Report List",
        api_path: "/report/reminder/list",
      },
      Detail: {
        method: "get",
        module: "rms",
        request_name: "Get Reminder Report Detail",
        api_path: "/report/reminder/detail",
      },
    },
    Shift: {
      Filter: {
        method: "get",
        module: "rms",
        request_name: "Get Shift Report Filter",
        api_path: "/report/shift/filter",
      },
      List: {
        method: "post",
        module: "rms",
        request_name: "Get Shift Report List",
        api_path: "/report/shift-list",
      },
    },
    Ams: {
      Workorder: {
        Asset: {
          method: "post",
          module: "ams",
          request_name: "Get Workorder Asset Report",
          api_path: "/workorder/report",
        },
        Filters: {
          method: "post",
          module: "ams",
          request_name: "Get Workorder List Filter",
          api_path: "/workorder/report/filters",
        },
        Detail: {
          method: "get",
          module: "ams",
          request_name: "Get Workorder Report Detail",
          api_path: "/report/workorder-detail",
        },
      },
      Asset: {
        Lists: {
          method: "post",
          module: "ams",
          request_name: "Get Report Asset Lists",
          api_path: "/report/asset-lists",
        },
        Filters: {
          method: "post",
          module: "ams",
          request_name: "Get Workorder Report Filters",
          api_path: "/report/asset-filters",
        },
        Detail: {
          method: "get",
          module: "ams",
          request_name: "Get Asset Report Detail",
          api_path: "/report/asset-detail",
        },
      },
      Sparepart: {
        Lists: {
          method: "post",
          module: "ams",
          request_name: "Get Report Sparepart Lists",
          api_path: "/report/sparepart-list",
        },
        Filters: {
          method: "post",
          module: "ams",
          request_name: "Get Report Sparepart Filters",
          api_path: "/report/filter-sparepart",
        },
      },
      BASTHistory: {
        Lists: {
          method: "post",
          module: "ams",
          request_name: "Get list BAST report history",
          api_path: "/report/bast-list",
        },
        Filters: {
          method: "post",
          module: "ams",
          request_name: "Get filters BAST report history",
          api_path: "/report/bast-filter",
        },
      },
      ExpiredDate: {
        Lists: {
          method: "post",
          module: "ams",
          request_name: "Get list expired date report",
          api_path: "/report/expired-list",
        },
        Filters: {
          method: "post",
          module: "ams",
          request_name: "Get filters expired date report",
          api_path: "/report/expired-filter",
        },
      },
      History: {
        Lists: {
          method: "get",
          module: "rms",
          request_name: "Get History Export Report List",
          api_path: "/history-lists",
        },
        ExportReport: {
          method: "post",
          module: "rms",
          request_name: "Export Report",
          api_path: "/export-report",
        },
        Filter: {
          method: "get",
          module: "rms",
          request_name: "Get History Export Report Filter",
          api_path: "/history-filter",
        },
      },
    },
    Export: {
      Event: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report event list",
          api_path: "/export/report/event-list",
        },
      },
      Reminder: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report reminder list",
          api_path: "/export/report/reminder-list",
        },
      },
      LostFoundPackage: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report lost found package list",
          api_path: "/export/report/lost-found-package-list",
        },
      },
      Shift: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report shift list",
          api_path: "/export/report/shift-list",
        },
      },
      RmsEmployee: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report rms-employee list",
          api_path: "/export/report/employee-list",
        },
      },
      RmsTag: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report tag list",
          api_path: "/export/report/tag-list",
        },
      },
      Workorder: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report workoder list",
          api_path: "/export/report/workorder-list",
        },
      },
      Asset: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report asset list",
          api_path: "/export/report/asset-list",
        },
      },
      Sparepart: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report sparepart list",
          api_path: "/export/report/sparepart-list",
        },
      },
      ExpiredDate: {
        List: {
          method: "post",
          module: "static",
          request_name: "Export report expired list",
          api_path: "/export/report/expired-list",
        },
      },
    },
  },
  settings: {
    locationArea: {
      // Area
      getAreaLists: {
        method: "get",
        request_name: "Get area lists",
        api_path: "/area/lists/",
      },
      addArea: {
        method: "post",
        request_name: "Add area",
        api_path: "/area/add/",
      },
      editArea: {
        method: "put",
        request_name: "Edit area",
        api_path: "/area/edit/",
      },
      deleteArea: {
        method: "delete",
        request_name: "Delete area",
        api_path: "/area/delete/",
      },

      // Location
      getLocationLists: {
        method: "get",
        request_name: "Get location lists",
        api_path: "/location/getbyarea/",
      },
      addLocation: {
        method: "post",
        request_name: "Add location",
        api_path: "/location/add/",
      },
      editLocation: {
        method: "put",
        request_name: "Edit location",
        api_path: "/location/edit/",
      },
      deleteLocation: {
        method: "delete",
        request_name: "Delete location",
        api_path: "/location/delete/",
      },
    },

    tagCategory: {
      // Category
      getCategoryLists: {
        method: "get",
        request_name: "Get category lists",
        api_path: "/category/lists",
      },
      addCategory: {
        method: "post",
        request_name: "Add category",
        api_path: "/category/add",
      },
      editCategory: {
        method: "put",
        request_name: "Edit category",
        api_path: "/category/edit",
      },

      // Department
      getDepartmentLists: {
        method: "get",
        request_name: "Get department lists",
        api_path: "/department/lists",
      },

      // Tag
      getTagLists: {
        method: "get",
        request_name: "Get tag lists",
        api_path: "/tag/getTagByDepartmentAndCategory",
      },
      getTagById: {
        method: "get",
        request_name: "Get tag by ID",
        api_path: "/tag/get/",
      },
      addTag: {
        method: "post",
        request_name: "Add tag",
        api_path: "/tag/add",
      },
    },
    // testcomm
    testcomm: {
      getTestcomm: {
        method: "get",
        request_name: "Get Testcomm",
        api_path: "/testcomm-temp/lists",
        module: "ams",
      },
      getTestcommList: {
        method: "get",
        request_name: "Get Testcomm List",
        api_path: "/testcomm-temp/get/:id",
      },
      addTestcomm: {
        method: "post",
        module: "ams",
        request_name: "Add temp testcomm",
        api_path: "/testcomm-temp/add",
      },
      editTestcomm: {
        method: "put",
        module: "ams",
        request_name: "Edit testcomm",
        api_path: "/testcomm-temp/edit/",
      },
      deleteTestcommTemp: {
        method: "delete",
        module: "ams",
        request_name: "Delete testcomm temp",
        api_path: "/testcomm-temp/delete",
      },
    },

    // tools
    tools: {
      addToolsTemp: {
        method: "post",
        request_name: "Create new tools temp",
        module: "ams",
        api_path: "/tool-temp/add",
      },
      editToolsTemp: {
        method: "put",
        request_name: "Edit tools temp",
        module: "ams",
        api_path: "/tool-temp/edit",
      },
      deleteToolTemp: {
        method: "delete",
        request_name: "Delete tools temp",
        module: "ams",
        api_path: "/tool-temp/delete",
      },
      getToolTempList: {
        method: "get",
        request_name: "Get Tools List",
        module: "ams",
        api_path: "/tool-temp/list",
      },
    },
    cause: {
      getList: {
        method: "get",
        request_name: "Get Cause List",
        module: "ams",
        api_path: "/cause/lists",
      },
      addCause: {
        method: "post",
        module: "ams",
        request_name: "Add Cause",
        api_path: "/cause/add",
      },
      editCause: {
        method: "put",
        module: "ams",
        request_name: "Edit Cause",
        api_path: "/cause/edit",
      },
      deleteCause: {
        method: "delete",
        module: "ams",
        request_name: "Delete Cause",
        api_path: "/cause/delete",
      },
    },
    solution: {
      getTempList: {
        method: "get",
        request_name: "Get Solution Temp List",
        module: "ams",
        api_path: "/solutionTemp/lists",
      },
      getTempById: {
        method: "get",
        request_name: "Get Solution Temp By Id",
        module: "ams",
        api_path: "/solutionTemp/get/",
      },
      addSolutionTemp: {
        method: "post",
        module: "ams",
        request_name: "Create Solution Temp",
        api_path: "/solutionTemp/add",
      },
      editSolutionTemp: {
        method: "put",
        module: "ams",
        request_name: "Edit Solution Temp",
        api_path: "/solutionTemp/edit",
      },
      deleteSolutionTemp: {
        method: "delete",
        module: "ams",
        request_name: "Delete Solution Temp",
        api_path: "/solutionTemp/delete",
      },
    },
    perfectroomType: {
      addPerfectroomType: {
        method: "post",
        module: "ams",
        request_name: "Create Perfectroom Type",
        api_path: "/perfectroom/add",
      },
      addPerfectroomAssetType: {
        method: "post",
        module: "ams",
        request_name: "Add Asset Type on Perfectroom Type",
        api_path: "/perfectroom/add-asset-type",
      },
      editPerfectroomType: {
        method: "put",
        module: "ams",
        request_name: "Edit Perfectroom Type",
        api_path: "/perfectroom/edit",
      },
      editPerfectroomAssetType: {
        method: "put",
        module: "ams",
        request_name: "Edit Perfectroom Asset Type",
        api_path: "/perfectroom/edit-asset-type",
      },
      deletePerfectroomType: {
        method: "delete",
        module: "ams",
        request_name: "Delete Perfectroom Type",
        api_path: "/perfectroom/delete",
      },
      deletePerfectroomAssetType: {
        method: "delete",
        module: "ams",
        request_name: "Delete Perfectroom Asset Type",
        api_path: "/perfectroom/delete-asset-type",
      },
      getListPerfectroomType: {
        method: "get",
        module: "ams",
        request_name: "Get Lists Perfectroom Type",
        api_path: "/perfectroom/lists",
      },
      getListPerfectroomAssetType: {
        method: "get",
        module: "ams",
        request_name: "Get Lists Perfectroom Asset Type",
        api_path: "/perfectroom/asset-type-lists",
      },
      getPerfectroomAssetTypeList: {
        method: "get",
        module: "ams",
        request_name: "Get Lists Perfectroom Asset Type",
        api_path: "perfectroom/asset-type-lists",
      },
    },
    serviceCost: {
      getListServiceCost: {
        method: "get",
        module: "ams",
        request_name: "Get Service Cost",
        api_path: "/cost-temp/lists",
      },
      addServiceCost: {
        method: "post",
        module: "ams",
        request_name: "Create New Service Cost",
        api_path: "/cost-temp/add",
      },
      updateServiceCost: {
        method: "put",
        module: "ams",
        request_name: "Update Service Cost",
        api_path: "/cost-temp/edit",
      },
      removeServiceCost: {
        method: "put",
        module: "ams",
        request_name: "Remove service cost",
        api_path: "/cost-temp/remove",
      },
    },
  },

  reminder: {
    addReminder: {
      method: "post",
      request_name: "Add reminder",
      api_path: "/reminder/add",
    },
    editReminder: {
      method: "put",
      request_name: "Edit reminder",
      api_path: "/reminder/edit",
    },
    getReminderLists: {
      method: "get",
      request_name: "Get reminder lists",
      api_path: "/reminder/lists",
    },
  },

  notification: {
    getLists: {
      method: "get",
      request_name: "Get notification lists",
      api_path: "/notification/getLists",
    },
    read: {
      method: "put",
      request_name: "Update notification status read",
      api_path: "/notification/read",
    },
  },

  general: {
    department: {
      addDepartment: {
        method: "post",
        request_name: "Post department lists",
        api_path: "/department/add",
      },
      editDepartment: {
        method: "put",
        request_name: "Put department lists",
        api_path: "/department/edit",
      },
      getLists: {
        method: "get",
        request_name: "Get department lists",
        api_path: "/department/lists",
      },
      getDepartment: {
        method: "get",
        request_name: "Get department",
        api_path: "/department/get",
      },
    },
    tag: {
      getTagByCategory: {
        method: "get",
        request_name: "Get tag lists by category",
        api_path: "/tag/getbycategory",
      },
      addTag: {
        method: "post",
        request_name: "Add tag",
        api_path: "/tag/add",
      },
      editTag: {
        method: "post",
        request_name: "Edit tag",
        api_path: "/tag/edit",
      },
      getTagByDepartmentId: {
        method: "get",
        request_name: "Get tag lists by department",
        api_path: "/tag/getbydepartment",
      },
    },
    user: {
      addUser: {
        method: "post",
        request_name: "Add user",
        api_path: "/user/add/",
      },
      addFCMToken: {
        method: "post",
        request_name: "Add user fcm token",
        api_path: "/user/addFCMToken",
      },
      removeUserFCMToken: {
        method: "post",
        request_name: "Remove user fcm token",
        api_path: "/user/removeUserFCMToken",
      },
      editUser: {
        method: "put",
        request_name: "Edit user",
        api_path: "/user/edit/",
      },
      getUser: {
        method: "get",
        request_name: "Get user",
        api_path: "/user/get/",
      },
      getMultipleUser: {
        method: "get",
        request_name: "Get multiple user",
        api_path: "/user/getMultiple/",
      },
      getUserLists: {
        method: "post",
        request_name: "Get user lists",
        api_path: "/user/getLists/",
      },
      getUserByDepartment: {
        method: "get",
        request_name: "Get user by department",
        api_path: "/user/getbydepartment/",
      },
      getUserByCompany: {
        method: "get",
        request_name: "Get user by company",
        api_path: "/user/getbycompany/",
      },
      getUserDepartments: {
        method: "get",
        request_name: "Get user departments",
        api_path: "/user/departments",
      },
    },
    location: {
      getLists: {
        method: "get",
        request_name: "Get location lists",
        api_path: "/location/getlists/",
      },
      getListsByAreaId: {
        method: "get",
        request_name: "Get location lists",
        api_path: "location/",
        module: "ams",
      },
      getLocationEventOverview: {
        method: "get",
        request_name: "Get location event overview lists",
        api_path: "/location/getLocationEventOverview/",
      },
    },
    priority: {
      add: {
        method: "post",
        request_name: "Add priority",
        api_path: "/priority/add/",
      },
      edit: {
        method: "put",
        request_name: "Edit priority",
        api_path: "/priority/edit/",
      },
      getLists: {
        method: "get",
        request_name: "Get priority lists",
        api_path: "/priority/lists/",
      },
    },
    role: {
      add: {
        method: "post",
        request_name: "Add Role",
        api_path: "/role/add/",
      },
      edit: {
        method: "put",
        request_name: "Edit Role",
        api_path: "/role/edit/",
      },
      getLists: {
        method: "get",
        request_name: "Get Role lists",
        api_path: "/role/lists/",
      },
    },
    checklist: {
      addTemp: {
        method: "post",
        request_name: "Add temp checklist",
        api_path: "/checklist/add/",
      },
      editTemp: {
        method: "put",
        request_name: "Edit temp checklist",
        api_path: "/checklist/edit/",
      },
      getTempLists: {
        method: "get",
        request_name: "Get temp checklist lists",
        api_path: "/checklist/getTempLists/",
      },
      getTempByDepartment: {
        method: "get",
        request_name: "Get temp checklist lists by department",
        api_path: "/checklist/getTempByDepartment/",
      },
    },
    checklistEvent: {
      getLists: {
        method: "get",
        request_name: "Get checklist event",
        api_path: "/checklistEvent/lists/",
      },
      updateBulk: {
        method: "put",
        request_name: "Update checklist event",
        api_path: "/checklistEvent/updateBulk/",
      },
    },
    shift: {
      add: {
        method: "post",
        request_name: "Add Shift",
        api_path: "/shift/add/",
      },
      edit: {
        method: "put",
        request_name: "Edit Shift",
        api_path: "/shift/update",
      },
      getLists: {
        method: "get",
        request_name: "Get Shift lists",
        api_path: "/shift/lists/",
      },
    },
    EventType: {
      Category: {
        list: {
          method: "GET",
          request_name: "Get Category Event Type",
          api_path: "/event-type/categories",
        },
        add: {
          method: "POST",
          request_name: "Add Category Event Type",
          api_path: "/event-type/category/create",
        },
        upadate: {
          method: "PUT",
          request_name: "Update Category Event Type",
          api_path: "/event-type/category/update",
        },
        delete: {
          method: "PUT",
          request_name: "Delete Category Event Type",
          api_path: "/event-type/category/delete",
        },
      },
      Tag: {
        list: {
          method: "GET",
          request_name: "Get tag Event Type",
          api_path: "/event-type/tags",
        },
        add: {
          method: "POST",
          request_name: "Add tag Event Type",
          api_path: "/event-type/tag/create",
        },
        delete: {
          method: "PUT",
          request_name: "Delete tag Event Type",
          api_path: "/event-type/tag/delete",
        },
      },
    },
    subscription: {
      add: {
        method: "post",
        request_name: "Add Subscription",
        api_path: "/subscription",
      },
      edit: {
        method: "put",
        request_name: "Edit Subscription",
        api_path: "/subscription",
      },
      getLists: {
        method: "get",
        request_name: "Get Subscription lists",
        api_path: "/subscriptions",
      },
    },
    client_subscription: {
      add: {
        method: "post",
        request_name: "Add Client Subscription",
        api_path: "/client-subscription",
      },
      getLists: {
        method: "get",
        request_name: "Get Client Subscription lists",
        api_path: "/client-subscriptions",
      },
      getTicketLists: {
        method: "get",
        request_name: "Get Client Ticket lists",
        api_path: "/client-subscription/ticket",
      },
    },
  },
};

export default api_constant;
